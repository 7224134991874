import { Button, List, message, Modal, Popconfirm, Spin, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import ExpedientMovementService from '../../../services/expedient_movement.service';
import { purifyStyledText } from '../../../util/purifyText';
import "./styles.css";
import { CloseCircleOutlined, SaveOutlined, UserOutlined } from '@ant-design/icons';
import StyledTextArea from '../../StyledTextArea';
import { StyledText } from '../../StyledTextArea/components/StyledText';
import moment from 'moment';
import { ReactComponent as EditIcon } from "../../../assets/assets2_0/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/assets2_0/delete.svg";
import { parseJwt } from '../../../util/jwt.helper';
const MovementComments = ({ expedient_movement, onCancel, onNewComment, onDeleteComment, setCachedComments }) => {

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState(null);
  const [controlledValue, setControlledValue] = useState('');
  const [reload, setReload] = useState(false);
  const { user } = parseJwt();
  const [plainText, setPlainText] = useState(null);

  const [commentToEdit, setCommentToEdit] = useState(null);

  const movementExists = expedient_movement && expedient_movement.id;

  const fetchComments = () => {

    if (movementExists) {
      setLoading(true);
      ExpedientMovementService.getComments(expedient_movement.id)
        .then(({ data }) => {
          setComments([...data]);
        })
        .catch(err => {
          message.error("No se pudieron cargar los comentarios");
          console.log(err)
        })
        .finally(() => setLoading(false));
    }

  }

  const saveComment = async () => {
    const emptyComment = plainText === null || plainText === undefined || plainText.trim() === "";
    if (emptyComment) {
      message.error("El contenido del comentario no puede estar vacío");
      return;
    }

    if (movementExists) {
      setSaving(true)
      let comment_data = {};
      Object.assign(comment_data, {
        content: purifyStyledText(newComment),
        expedient_movement_id: expedient_movement.id,
      });
      const form_data = new FormData();
      form_data.set("body", JSON.stringify({ ...comment_data }));

      try {
        const createdComment = await ExpedientMovementService.createComment(form_data);
        setControlledValue(' ');
        setControlledValue('');
        setComments([createdComment.data, ...comments,]);
        onNewComment && onNewComment();
      } catch (error) {
        console.log(error);
        message.error("No se pudo guardar el comentario");
      } finally {
        setSaving(false)
      }

    } else {
      let static_comment = {
        id: Math.random(),
        content: purifyStyledText(newComment),
        created_at: moment(),
        user_id: user.id,
        user: {
          firstname: user.firstname,
          lastname: user.lastname
        }
      };
      setControlledValue(' ');
      setTimeout(() => {
        setControlledValue('');
      }, 50);
      onNewComment && onNewComment();
      setComments([static_comment, ...comments]);
      setCachedComments && setCachedComments([static_comment, ...comments]);
    }

  }

  const editComment = async () => {

    const emptyComment = plainText === null || plainText === undefined || plainText.trim() === "";
    if (emptyComment) {
      return message.error("El contenido del comentario no puede estar vacío");
    }

    if (movementExists) {
      setSaving(true)
      let comment_data = {};
      Object.assign(comment_data, {
        content: purifyStyledText(newComment),
        comment_id: commentToEdit.id,
        expedient_movement_id: expedient_movement.id,
      });
      const form_data = new FormData();
      form_data.set("body", JSON.stringify({ ...comment_data }));

      try {
        const updatedCOmment = await ExpedientMovementService.updateExpedientMovementComment(form_data);
        setControlledValue(' ');
        setControlledValue('');
        setReload(!reload);
        setCommentToEdit(null);
        /* setComments([...comments, createdComment.data]); */
      } catch (error) {
        console.log(error);
        message.error("No se pudo guardar el comentario");
      } finally {
        setSaving(false)
      }
    } else {
      let static_comment = {
        ...commentToEdit,
        content: purifyStyledText(newComment),
        updated_at: moment(),
      };
      let updated_comments = [...comments.map(c => {
        if (c.id === commentToEdit.id) {
          return static_comment
        } else {
          return c
        }
      })];
      setControlledValue(' ');
      setTimeout(() => {
        setControlledValue('');
      }, 50);
      setComments(updated_comments);
      setCachedComments && setCachedComments(updated_comments);
      setCommentToEdit(null);
    }


  }

  const deleteComment = async (comment_id) => {
    if (movementExists) {
      try {
        const deletedComment = await ExpedientMovementService.deleteComment(comment_id);
        onDeleteComment && onDeleteComment();
        return deletedComment;
      } catch (error) {
        console.log(error);
        message.error("No se pudo eliminar el comentario");
      }
    } else {
      let updated_comments = [...comments.filter(c => c.id !== comment_id)];
      setControlledValue(' ');
      setTimeout(() => {
        setControlledValue('');
      }, 50);
      onDeleteComment && onDeleteComment();
      setComments(updated_comments);
      setCachedComments && setCachedComments(updated_comments)

    }

  }

  useEffect(() => {
    fetchComments()
  }, [reload])

  return (
    <div className='movement-comments-container' style={{ height: commentToEdit ? "auto" : "60vh" }}>
      <div className='movement-comments-list'>
        {loading ? <Spin /> :
          <List
            itemLayout='vertical'
            locale={{ emptyText: commentToEdit ? "Actualizar comentario" : "No hay comentarios" }}
            style={{ width: "95%", overflow: "auto" }}
            dataSource={commentToEdit ? [] : [...comments]}
            renderItem={(comment) => {
              const { user } = comment;
              const author = `${user.firstname ? user.firstname.charAt(0).toUpperCase() + user.firstname.slice(1) : ""} ${user.lastname ? user.lastname.charAt(0).toUpperCase() + user.lastname.slice(1) : ""}`

              return (
                <List.Item
                  style={{ display: "flex", flexDirection: "column", gap: "10px" }}
                  actions={[
                    <Tooltip title="Editar comentario" trigger="hover">
                      <EditIcon
                        width={"14.313"}
                        style={{ cursor: "pointer" }}
                        color={"#002A4E"}
                        onClick={() => {
                          setCommentToEdit(comment);
                          setControlledValue(comment.content);
                        }}
                      />
                    </Tooltip>,
                    <Tooltip title="Eliminar comentario" trigger="hover">
                      <Popconfirm
                        title="¿Seguro desea eliminar este comentario?"
                        onConfirm={() => {
                          deleteComment(comment.id)
                            .then(({ data }) => {
                              setComments([...comments].filter(comment => comment.id !== Number(data.comment_id)));
                              message.success("Comentario eliminado");
                            })
                            .catch(err =>
                              message.error("No se ha podido eliminar el comentario")
                            );
                        }}
                      >
                        <DeleteIcon
                          width={"13.313"}
                          style={{ cursor: "pointer" }}
                          color={"#95001D"}
                        />
                      </Popconfirm>
                    </Tooltip>
                  ]}
                  key={comment.id}>
                  <div className='comment-author' >
                    <span style={{ color: "#35b6bd" }}>
                      <UserOutlined /> {author}
                    </span>
                    <TimePassed comment={comment} />
                  </div>
                  <div className='comment-content'>
                    {<StyledText styledText={purifyStyledText(comment.content)} />}</div>
                </List.Item>
              )
            }}
          />
        }
      </div>
      <div className='movement-comments-modal-footer' style={{ height: commentToEdit ? "200px" : "30%" }}>
        <div className="comment-input">
          <StyledTextArea
            /* extraOptions={["list"]} */
            initialValue={newComment}
            controlledValue={controlledValue}//Update this value to force input to update
            placeholder='   Escribe tu comentario...'
            customStyle={{
              overflow: "auto",
              maxHeight: "90px",
            }}
            onChange={(styled_text, plain_text) => {
              /* debouncedSearch(plain_text); */
              setPlainText(plain_text)
              setNewComment(styled_text);
            }}
          />
        </div>
        <div className='comment-control'>
          <Button
            style={{ fontSize: "16px", width: "160px" }}
            type="danger"
            onClick={() => {
              if (commentToEdit) {
                setCommentToEdit(null);
                setControlledValue(" ");
                setTimeout(() => {
                  setControlledValue('');
                }, 50);
              } else {
                onCancel && onCancel()
              }
            }
            }
            className="ant-btn warn responsive-input responsive-button"
            htmlType="button"
          >
            <CloseCircleOutlined style={{ fontSize: "16px" }} /> {commentToEdit ? "Cancelar" : "Cerrar"}
          </Button>
          <Button loading={saving} className={"responsive-input responsive-button"} ghost type="primary" style={{ fontSize: "16px", width: "160px" }} onClick={() => { commentToEdit ? editComment() : saveComment() }}>
            <SaveOutlined /> {commentToEdit ? "Actualizar" : "Guardar"}
          </Button>
        </div>

      </div>
    </div >
  )
}

export default MovementComments;

const TimePassed = ({ comment }) => {
  moment.locale("es");
  const { created_at, updated_at } = comment;
  const recent_date = updated_at !== created_at ? updated_at : created_at;

  return (<span style={{ color: "#8f8f8f" }}>{moment(recent_date).fromNow()} &nbsp;&nbsp;</span>)
}
