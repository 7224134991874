import { CopyOutlined, InboxOutlined } from "@ant-design/icons";
import { Button, Modal, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import axios, { authHeaders } from "../../util/Api";
import { onCopy } from "../../routes/RtfPage/RtfInfo";
import CloseRequestLinkButton from "./CloseRequestLinkButton";
import SendRequestLinkWhatsapp from "./SendRequestLinkWhatsapp";
import SendRequestLinkEmail from "./SendRequestLinkEmail";
import RequestFilesForm from "./RequestFilesForm";
import ButtonGroup from "antd/lib/button/button-group";
import RequestedFileList from "./RequestedFileList";
import ExpedientDetailContainer from "../../unstatedStores/ExpedientDetailState";

const RequestExpedientFiles = ({ expedient }) => {
  const [modalV, setModalV] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestFileInfo, setRequestFileInfo] = useState();
  const { client, uuid } = expedient;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const expedientState = ExpedientDetailContainer.useContainer();

  useEffect(() => {
    setLoading(true);
    expedientState.updateScan({ files: [] });
    getRequestFile(expedient.uuid)
      .then(resp => {
        setRequestFileInfo(resp);
        expedientState.updateScan(resp);
      })
      .catch(e => console.log(e))
      .finally(() => setLoading(false));
  }, [uuid]);

  return (
    <>
      {!loading && client && (
        <Modal
          width={isTabletOrMobile ? "99vw" : "45vw"}
          title={`Solicitar documentos al cliente ${client.firstname} ${client.lastname}`}
          footer={
            requestFileInfo ? (
              <>
                <CloseRequestLinkButton reqFileInfo={requestFileInfo} />
                <RequestLink expedient={expedient} />
                <SendRequestLinkWhatsapp expedient={expedient} />
                <SendRequestLinkEmail
                  reqFile={requestFileInfo}
                  disabled={!client.email}
                />
              </>
            ) : null
          }
          visible={modalV}
          onCancel={() => {
            setModalV(false);
          }}
        >
          <RequestFilesForm
            onSubmitted={setRequestFileInfo}
            reqFileInfo={requestFileInfo}
            expedient={expedient}
          />
        </Modal>
      )}
      <ButtonGroup compact={true}>
        <Tooltip title="Solicitar documentos al cliente">
          <Button
            loading={loading}
            type="primary"
            icon={<InboxOutlined />}
            onClick={() => client ? setModalV(true) : message.error("Necesita asociar un cliente al expediente para solicitar documentos")}
          >
            Solicitar
          </Button>
        </Tooltip>
        <RequestedFileList
          files={requestFileInfo ? requestFileInfo.files : []}
        />
      </ButtonGroup>
    </>
  );
};

const RequestLink = ({ expedient }) => {
  return (
    <Button
      size="small"
      className="option-button"
      icon={<CopyOutlined />}
      onClick={() => {
        onCopy(`${window.location.origin}/scan#${expedient.uuid}`);
      }}
    >
      Copiar link
    </Button>
  );
};

async function getRequestFile(uuid) {
  const resp = await axios.get(`/expedient-file-request/${uuid}`, {
    headers: {
      ...authHeaders()
    }
  });
  return resp.data;
}

export default RequestExpedientFiles;
