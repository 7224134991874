import { Tooltip, Modal } from "antd";
import "./index.css";
import React, { useEffect } from "react";
import { useTrialDays } from "../../hooks/useTrialDays";
import AltaButton from "../../components/AltaButton/AltaButton";
import useSignOut from "../../hooks/useSignOut";
import { CloseOutlined, WarningOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import QuickActionsMenu from "../QuickActionsMenu";
import TicketService from "../../services/ticket.service";

const TrialDaysCounter = () => {
  const { warning } = Modal;

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 980px)" });
  const trialData = useTrialDays();
  const signOut = useSignOut();
  const latestSubscription = trialData ? trialData.latestSubscription : null;
  const is_trial = trialData ? trialData.is_trial : false;
  const remaining_days = is_trial ? trialData.remaining_days + 1 : null;
  const trial_ended = is_trial ? trialData.trial_ended : null;

  const alertText = latestSubscription
    ? "Reactiva tu cuenta y volve a disfrutar de todos los beneficios de nuestras calculadoras y herramientas! "
    : "Suscribite y disfruta de todos los beneficios de nuestras calculadoras y herramientas! ";
  const redirectUrl = latestSubscription
    ? "https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=" +
      latestSubscription.subscription_plan.plan_id_reactivation
    : "https://www.liquidacionesjuridicas.com/wp-content/themes/businex/scripts/boton_suscribite_trial.php";
  const okText = latestSubscription
    ? "Reactiva tu cuenta ahora"
    : "Suscribirme ahora";

  const handleLinkClick = () => {
    TicketService.requestCalculadoras("Reactivando cuenta");
  };

  return (
    <>
      {is_trial && (
        <div
          style={{
            height: isTabletOrMobile && trial_ended ? "100px" : "40px",
            position: "relative",
            color: "black"
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              backgroundColor: "#f9eeba",
              alignItems: "flex-end",
              position: "absolute",
              top: "-50px",
              padding: "60px 0px 10px 0px",
              fontSize: "14px",
              justifyContent: "center",
              color: "#fff",
              color: "black",
              alignContent: "center",
              textAlign: "center",
              borderBottom: "2px solid",
              borderBottomColor: "#a9a9a9",
              zIndex: 50
            }}
          >
            {trial_ended && (
              <div
                style={{
                  display: "flex",
                  gap: "3px",
                  flexDirection: isTabletOrMobile ? "column" : "row"
                }}
              >
                <a href={redirectUrl} onClick={handleLinkClick}>
                  <u>
                    {alertText}
                    {okText}
                  </u>
                </a>
                <div
                  style={{
                    position: "absolute",
                    right: "10px",
                    bottom: "10px",
                    cursor: "pointer"
                  }}
                >
                  <CloseOutlined onClick={() => signOut()} />
                </div>
              </div>
            )}

            {!trial_ended && (
              <>
                <u>{`Restan ${
                  remaining_days > 0 ? remaining_days : 0
                } días de trial`}</u>
                &nbsp;&nbsp;
                <AltaButton
                  fixed={false}
                  extra={remaining_days > 0 ? remaining_days : 0}
                />
              </>
            )}
          </div>

          {/* Mask to lock all functionality */}
          {/* Mask above notification */}
          {trial_ended && (
            <>
              <div
                style={{
                  position: isTabletOrMobile ? "fixed" : "absolute",
                  zIndex: 52,
                  width: "100%",
                  height: "600px",
                  borderRadius: isTabletOrMobile ? "10px" : "40px",
                  top: isTabletOrMobile ? "-500px" : "-600px",
                  backgroundColor: "black",
                  opacity: "0.5"
                }}
              ></div>
              {/* Mask below notification */}
              <div
                style={{
                  position: "fixed",
                  top: "-600px",
                  zIndex: 49,
                  width: "100vw",
                  height: "200vh",
                  backgroundColor: "black",
                  opacity: "0.5"
                }}
              ></div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default TrialDaysCounter;
