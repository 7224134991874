import React from "react";
import { Modal, Typography } from "antd";
import { VideoTutorialButton } from "./VideoTutorialModal";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as CloseIcon } from "../assets/assets2_0/close-icon.svg"
import Antd4FilterExpedientForm from "../law-components/Forms/FilterExpedient/Antd4FilterExpedientForm";

function ExpedientFilterModal({ handleOk, handleCancel, visible }) {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div>
      <Modal
        closeIcon={<CloseIcon className="clickable-icon" />}
        title={<HStack gap={"0px"}>
          <Typography.Text>Filtrar Expedientes</Typography.Text>
          <VideoTutorialButton tutorial={"listados"} />
        </HStack>}
        destroyOnClose={true}
        visible={visible}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={null}
        className="responsive-modal"
        width={!isTabletOrMobile ? "75vw" : "95vw"}
        bodyStyle={{ minHeight: "505px" }}
        centered

      >
        <Antd4FilterExpedientForm onCancel={handleCancel} onDone={handleCancel} />
      </Modal>
    </div>
  );
}

export const HStack = ({ children, gap, style }) => {
  return (
    <div style={{ display: "flex", gap: gap || "10px", alignItems: "center", ...style }}>
      {children}
    </div>
  );
};

export default ExpedientFilterModal;
