import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import { useMediaQuery } from "react-responsive";
import Antd4ProvinceSelect from "../../law-components/PrebuiltFormComponents/Antd4ProvinceSelect";
import Antd4CountrySelect from "../../law-components/PrebuiltFormComponents/Antd4CountrySelect";
import StudioService from "../../services/studio.service";



export function CompleteStudioForm({ onFinish, studio }) {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const [loading, setLoading] = useState(false)
    const [country, setCountry] = useState();

    const handleSubmit = (values) => {
        setLoading(true)
        StudioService.verifyStudio(studio.id, values)
            .then(() => {
                onFinish && onFinish()
            })
            .finally(() => setLoading(false))
    }

    return (
        <Form layout="vertical" onFinish={handleSubmit}>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between"
                }}
            >

                <Form.Item
                    style={{ width: isTabletOrMobile ? "99%" : "49%", padding: ".5% .25%" }}
                    name="country_id"
                    rules={[{ required: true, message: "Esta campo es requerido" }]}
                    label="Pais"
                    hasFeedback
                >
                    <Antd4CountrySelect onChange={c => setCountry(c)} />

                </Form.Item>
                <Form.Item
                    style={{ width: isTabletOrMobile ? "99%" : "49%", padding: ".5%" }}
                    name="province_id"
                    rules={[{ required: true, message: "Esta campo es requerido" }]}
                    label="Provincia/Estado"
                    hasFeedback
                >
                    <Antd4ProvinceSelect country={country} />
                </Form.Item>
                <Form.Item
                    style={{ width: isTabletOrMobile ? "99%" : "100%", padding: ".5%" }}
                    name="name"
                    rules={[{ required: true, message: "Esta campo es requerido" }]}
                    label="Nombre del estudio"
                    hasFeedback
                >
                    <Input className="responsive-input" autoComplete="cc-csc" />
                </Form.Item>
                <Form.Item
                    style={{ width: isTabletOrMobile ? "99%" : "49%", padding: ".5%" }}
                    name="email"
                    initialValue={studio.email}
                    rules={[{ required: true, message: "Esta campo es requerido" }]}
                    label="Correo electronico"
                    hasFeedback
                >
                    <Input readOnly className="responsive-input" autoComplete="cc-csc" />
                </Form.Item>
                <Form.Item
                    style={{ width: isTabletOrMobile ? "99%" : "49%", padding: ".5%" }}
                    name="phone"
                    rules={[{ required: true, message: "Esta campo es requerido" }]}
                    label="Telefono"
                    hasFeedback
                >
                    <Input className="responsive-input" autoComplete="cc-csc" />
                </Form.Item>
                <Form.Item
                    style={{ width: isTabletOrMobile ? "99%" : "49%", padding: ".5%" }}
                    name="firstname"
                    rules={[{ required: true, message: "Esta campo es requerido" }]}
                    label="Nombres"
                    hasFeedback
                >
                    <Input className="responsive-input" autoComplete="cc-csc" />
                </Form.Item>
                <Form.Item
                    style={{ width: isTabletOrMobile ? "99%" : "49%", padding: ".5%" }}
                    name="lastname"
                    rules={[{ required: true, message: "Esta campo es requerido" }]}
                    label="Apellidos"
                    hasFeedback
                >
                    <Input className="responsive-input" autoComplete="cc-csc" />
                </Form.Item>
                <Form.Item
                    style={{ width: isTabletOrMobile ? "99%" : "49%", padding: ".5%" }}
                    name="password"
                    rules={[
                        { required: true, message: "Campo requerido" },
                        {
                            min: 8,
                            message: "Su contraseña debe ser mayor a 8 caracteres."
                        }
                    ]}
                    label="Contraseña"
                >
                    <Input.Password className="responsive-input" autocomplete="chrome-off" />
                </Form.Item>
                <Form.Item
                    style={{ width: isTabletOrMobile ? "99%" : "49%", padding: ".5%" }}
                    name="password_confirm"
                    rules={[
                        { required: true, message: "Campo requerido" },
                        {
                            min: 8,
                            message: "Su contraseña debe ser mayor a 8 caracteres."
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue("password") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject("Las contraseñas no coinciden");
                            }
                        })
                    ]}
                    label="Confirme contraseña"
                >
                    <Input.Password className="responsive-input" autocomplete="chrome-off" />
                </Form.Item>
                <Form.Item label="">
                    <Button loading={loading} type="primary" htmlType="submit">Completar</Button>
                </Form.Item>
            </div>
        </Form>
    );
}