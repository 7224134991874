import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { CompleteStudioForm } from "./CompleteStudioForm";
import { useStudio } from "../../hooks/useStudio";

export function CompleteStudioModal() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const studio = useStudio([])

  useEffect(() => {
    if (!studio) return;

    if (studio.is_verified === 0) {
      setIsModalOpen(true)
    }

  }, [studio])

  return <>
    <Modal
      onCancel={() => setIsModalOpen(false)}
      title="Completa la información del estudio." open={isModalOpen}
      footer={null}>
      {studio && <CompleteStudioForm
        studio={studio}
        onFinish={() => setIsModalOpen(false)} />}
    </Modal>

  </>


}