import React, { cloneElement, useEffect, useMemo, useRef, useState } from "react";
import { useMeasure } from "./use-measure";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Pager } from "./Pager";
import { of } from "rxjs";

const TabContainer = styled.div`
  overflow-y: hidden;
  box-shadow: none;
  display: flex;
  justify-content: center;
`;

const TabList = styled.div`
  display: inline-flex;
  position: relative;
  border-radius: ${p => p.borderRadius || '18px'};
  background: #e2e2e2;
  width: ${p => p.width || 'auto'};
  max-width: 100%;
`;

const TabItem = styled(motion.button)`
  white-space: nowrap;
  position: relative;
  z-index: 10;
  -webkit-appearance: none;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  box-shadow: none;
  cursor: pointer;
  text-decoration: none;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  padding: ${p => p.padding || '1ch 2.5ch'};
  display: inline-flex;
  align-items: center;
  gap: 1ch;
  text-size-adjust: none;
  text-overflow: ellipsis;
  line-height: 1;
  font-size: ${p => p.fontSize || "0.875rem"};
  @media (max-width: 768px) {
    font-size: 0.625rem;
  }
  justify-content: center;
  background: transparent;
  color: ${p => (p.isActive ? "#fff" : "#969696")};
  overflow: hidden;
  border-radius: ${p =>
    p.isAtStart
      ? "33px 0px 0px 33px"
      : p.isAtEnd
        ? "0px 33px 33px 0px"
        : "0px"};
`;

const Slider = styled(motion.div)`
  height: 100%;
  z-index: 5;
  border-radius: ${p => p.borderRadius || '33px'};
  position: absolute;
  left: 0;
  background: #3D529E;
`;

export const CustomSwitch = ({ defaultValue, onChange, options, fontSize, padding, borderRadius, width, input = false, mode = "default", disabled = false }) => {

  const [value, setValue] = useState(defaultValue || 0);
  const childRefs = useRef(new Map());
  const tabListRef = useRef();
  const [slider, setSlider] = useState({ left: 0, right: 0 });
  const { bounds, ref } = useMeasure();

  // measure our elements
  useEffect(() => {
    const target = childRefs.current.get(value);
    const container = tabListRef.current;
    if (target) {
      const cRect = container.getBoundingClientRect();

      // when container is `display: none`, width === 0.
      // ignore this case
      if (cRect.width === 0) {
        return;
      }

      const tRect = target.getBoundingClientRect();
      const left = tRect.left - cRect.left;
      const right = cRect.right - tRect.right;

      setSlider({
        hasValue: true,
        left: left,
        right: right
      });
    }
  }, [value, bounds]);

  return (
    <TabContainer ref={ref} className={input ? "responsive-input" : ""} >
      <TabList ref={tabListRef} borderRadius={borderRadius || '33px'} width={width}>
        {slider.hasValue && (
          <Slider
            borderRadius={borderRadius || '33px'}
            layout
            transition={{
              bounceDamping: 2,
            }}
            initial={false}
            style={{
              left: slider.left,
              right: slider.right,
              backgroundColor: options[value].color
            }}
          />
        )}
        {options.map((tab, i) => {

          const toggle_value = mode === "toggle" && value === 0 ? 1 : 0;
          const new_value = mode === "toggle" ? toggle_value : i;

          return (
            <TabItem
              className="custom-tab-item"
              type="button"
              fontSize={fontSize || "0.75em"}
              padding={padding}
              height="1.2em"
              key={tab.name}
              isActive={i === value}
              isAtStart={i === 0}
              isAtEnd={i === options.length - 1}
              ref={el => childRefs.current.set(i, el)}
              onClick={(e) => {
                if (!disabled) {
                  setValue(new_value);
                  onChange(options[new_value]);
                  e.stopPropagation()
                }
              }}
            >
              {tab.name}
            </TabItem>
          )
        })}
      </TabList>
    </TabContainer>)
}

export const Tabs = ({ tabs }) => {
  const [value, setValue] = useState(0);
  const childRefs = useRef(new Map());
  const tabListRef = useRef();
  const [slider, setSlider] = useState({ left: 0, right: 0 });
  const { bounds, ref } = useMeasure();

  // measure our elements
  useEffect(() => {
    const target = childRefs.current.get(value);
    const container = tabListRef.current;
    if (target) {
      const cRect = container.getBoundingClientRect();

      // when container is `display: none`, width === 0.
      // ignore this case
      if (cRect.width === 0) {
        return;
      }

      const tRect = target.getBoundingClientRect();
      const left = tRect.left - cRect.left;
      const right = cRect.right - tRect.right;

      setSlider({
        hasValue: true,
        left: left,
        right: right
      });
    }
  }, [value, bounds]);

  return (
    <div>
      <TabContainer ref={ref}>
        <TabList ref={tabListRef}>
          {slider.hasValue && (
            <Slider
              layout
              transition={{
                bounceDamping: 2
              }}
              initial={false}
              style={{
                left: slider.left,
                right: slider.right
              }}
            />
          )}
          {tabs.map((tab, i) => (
            <TabItem
              height="2em"
              key={tab.name}
              isActive={i === value}
              isAtStart={i === 0}
              isAtEnd={i === tabs.length - 1}
              ref={el => childRefs.current.set(i, el)}
              onClick={() => setValue(i)}
            >
              {cloneElement(tab.icon, { stroke: i === value ? "#fff" : "#969696", width: "0.9em" })} {tab.name}
            </TabItem>
          ))}
        </TabList>
      </TabContainer>
      <Pager value={value}>
        {tabs.map((tab, i) => (
          <div
            key={tab.name}
            style={{
              width: "100%",
              padding: "16px"
            }}
          >
            {cloneElement(tab.content, { inView: value === i })}
          </div>
        ))}
      </Pager>
    </div>
  );
}
