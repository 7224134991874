import { DownloadOutlined } from "@ant-design/icons";
import { Button, message, Tooltip, Popconfirm, Spin, Modal, Badge } from "antd";
import React, { useEffect, useState } from "react";
import { downloadPdf } from ".";
import ExpedientMovementService from "../../services/expedient_movement.service";
import { baseURL } from "../../util/Api";
import PostponeConfirm from "../LawCalendar/components/PostponeConfirm";
import ToggleInstaAccionScraping, { ToggleInstaAccionMevScraping } from "../LawCalendar/components/ToggleInstaAccionScraping";
import { DownloadFile } from "../ExpedientFiles/ExpedientFileList";
import { ReactComponent as EyeIcon } from "../../assets/assets2_0/eye.svg";
import PreviewFileModal from "../../modals/PreviewFileModal";
import { PiNoteLight } from "react-icons/pi";
import { IconContext } from "react-icons";
import MovementComments from "./components/MovementComments";

export const MovementActions = ({
  id,
  movement,
  setMovements,
  lastMovementUpdater,
  setMovementToEdit,
  modalSetVisible,
  reload,
  onChange,
  movements,
  deleteMovement,
  dateSortOrder,
  EditIcon,
  DeleteIcon,
  TogglePinMovement
}) => {
  const isMevMovement = !!movement.id_tramite_mev;
  const [isEditHovered, setIsEditHovered] = useState(false);
  const [isDeleteHovered, setIsDeleteHovered] = useState(false);
  const [isPreviewHovered, setIsPreviewHovered] = useState(false);

  const [previewFile, setPreviewFile] = useState(false);
  const [fileToPreview, setFileToPreview] = useState(undefined);
  const [ShowComments, setShowComments] = useState(false);

  const [commentsCount, setCommentsCount] = useState(movement.comments_count);

  if (id === -1) {
    return (
      <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
        {movement.files !== undefined && movement.files.length > 0 && (
          <Tooltip trigger="hover" title="Descargar adjunto">
            <Button
              onClick={() => {
                downloadPdf(
                  `${baseURL}/file/download/${movement.files[0].id}`,
                  movement.observations,
                  `.${movement.files[0].mimetype}`
                );
              }}
              type="primary"
              icon={<DownloadOutlined />}
              size="small"
            />
          </Tooltip>
        )}
        {isMevMovement ?
          <ToggleInstaAccionMevScraping
            movement={movement}
            onUpdated={_m => {
              setMovements(movements.map(m => (m.id === _m.id ? _m : m)));
              lastMovementUpdater.updatelastMovementCounter("x");
            }}
            key={movement.id}
          />
          : <ToggleInstaAccionScraping
            scrapingMovement={movement}
            onUpdated={_m => {
              setMovements(movements.map(m => (m.scraping_id === _m.scraping_id ? _m : m)));
              lastMovementUpdater.updatelastMovementCounter("x");
            }}
          />}
      </div>
    );
  } else if (isMevMovement) {
    if (movement.mev_job_steps && movement.mev_job_steps.length > 0 && movement.mev_job_steps[0].status !== 'finished') {
      return <Tooltip title="El movimiento se está sincronizando desde la MEV" trigger="hover">
        <Spin size="small" />
      </Tooltip>
    }
    return (
      <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
        {fileToPreview && (
          <PreviewFileModal
            showModal={previewFile}
            setShowModal={setPreviewFile}
            file={fileToPreview}
            setFileToPreview={setFileToPreview}
          />
        )}
        <Tooltip trigger="hover" title="Previsualizar">
          <EyeIcon
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color: isPreviewHovered ? "#0185F8" : "#002A4E",
              padding: 0,
              border: "none",
              background: "none",
              fontSize: "17px"
            }}
            onMouseEnter={() => setIsPreviewHovered(true)}
            onMouseLeave={() => setIsPreviewHovered(false)}
            onClick={() => {
              setFileToPreview(movement.files[0])
              setPreviewFile(true)

            }}
          />
        </Tooltip>
        <DownloadFile file={movement.files[0]} />
        <ToggleInstaAccionMevScraping
          movement={movement}
          onUpdated={_m => {
            setMovements(movements.map(m => (m.id === _m.id ? _m : m)));
            lastMovementUpdater.updatelastMovementCounter("x");
          }}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "1.5ch"
      }}
    >
      <Modal
        destroyOnClose={true}
        open={ShowComments}
        title={"Comentarios"}
        width={800}
        footer={null}
        onCancel={() => setShowComments(false)}
      >
        <MovementComments onCancel={() => setShowComments(false)} expedient_movement={movement} onNewComment={() => setCommentsCount(commentsCount + 1)} onDeleteComment={() => setCommentsCount(commentsCount - 1)} />
      </Modal>
      <Tooltip trigger="hover" title="Comentarios">
        <>
          <Badge count={commentsCount} size="small" offset={[-2, 5]} >
            <IconContext.Provider value={{ size: "22px", color: commentsCount > 0 ? "#35b6bc" : "#838383" }}>
              <PiNoteLight style={{ cursor: "pointer", marginTop: "5px" }} onClick={() => setShowComments(true)} />
            </IconContext.Provider>
          </Badge>
        </>
      </Tooltip>

      <Tooltip trigger="hover" title="Editar movimiento">
        <EditIcon
          width={"14.313"}
          style={{ cursor: "pointer" }}
          color={isEditHovered ? "#0185F8" : "#002A4E"}
          onMouseEnter={() => setIsEditHovered(true)}
          onMouseLeave={() => setIsEditHovered(false)}
          onClick={() => {
            setMovementToEdit({
              ...movement,
              movement_type: movement.movement_type.id
            });
            modalSetVisible(true);
          }}
        />
      </Tooltip>

      <TogglePinMovement
        fullwidth
        disabled={id === -1 || isMevMovement}
        size="default"
        movement={movement}
        onUpdated={_movement => {
          onChange(_movement);
          reload(state => !state);
        }}
      />
      {movement.movement_type_id !==
        Number.parseInt(process.env.REACT_APP_MOVEMENT_TYPE_ID_LEX_ESCRITO) && (
          <PostponeConfirm
            postponeFunc={ExpedientMovementService.postponeMovement}
            itemType={"movimiento"}
            item={movement}
            onPostponed={(_movement, attach) => {
              onChange(_movement);
              reload(state => !state);
            }}
          />
        )}
      <Tooltip title="Eliminar movimiento" trigger="hover">
        <Popconfirm
          title="¿Seguro desea eliminar este movimiento?"
          onConfirm={() => {
            deleteMovement(movement.id)
              .then(m => {
                reload(state => !state);
                message.success("Movimiento eliminado");
              })
              .catch(err =>
                message.error("No se ha podido eliminar el movimiento")
              );
          }}
        >
          <DeleteIcon
            width={"13.313"}
            style={{ cursor: "pointer" }}
            color={isDeleteHovered ? "#FF5879" : "#95001D"}
            onMouseEnter={() => setIsDeleteHovered(true)}
            onMouseLeave={() => setIsDeleteHovered(false)}
          />
        </Popconfirm>
      </Tooltip>
    </div>
  );
};
