import { useEffect, useState } from "react";
import { parseJwt } from "../util/jwt.helper";
import axios, {authHeaders} from '../util/Api';

export function useStudio(triggers){
  
  const [ studio, setStudio ] = useState();
  const user = parseJwt();
  const studio_id = user.user.studio_id;
  useEffect(() => {
    if(studio_id){
        axios.get('/my-studio', {
            headers: {
                ...authHeaders()
            }
        })
            .then(_studio => setStudio(_studio.data))
            .catch(e => console.log(e));
    }
    }, [studio_id, ...triggers]);

  return studio;
}
