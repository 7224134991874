import React, { useEffect, useRef, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { DownOutlined, EditOutlined, UpOutlined } from '@ant-design/icons';
import { StyledText } from './StyledText';
import StyledTextArea from '..';
import { purifyStyledText } from '../../../util/purifyText';
import "../styles.css"


const ExpandCollpaseStyledText = ({ rows = 2, children, editable = false, text, onEdit, copyable = false }) => {

    const [isExpanded, expand] = useState(false);
    const [editing, setEditing] = useState(false);
    const minHeightString = `${rows * 22}px`;
    const minHeightNumber = rows * 22;

    const [height, setHeight] = useState(0);
    const elementRef = useRef(null);

    useEffect(() => {
        if (elementRef) {
            setHeight(elementRef.current.clientHeight);
        }
    }, [text]);

    const isExpandable = height > minHeightNumber;
    const animate = isExpandable && editable;

    return (
        <div className={"collapse-expand-styled-text-container"} style={{ width: "100%" }}>
            <div style={{ position: "relative" }}>
                {editing ?
                    <div style={{ width: "95.6%", position: "relative", paddingLeft: editable ? "1px" : "0px" }}>
                        <StyledTextArea
                            autoFocus={true}
                            onEdit={(value, plain_text) => {
                                if ((purifyStyledText(text) !== value.trim()) && (plain_text && plain_text.trim() !== "")) {
                                    onEdit && onEdit(value)
                                    expand(false)
                                }
                                setEditing(false)
                            }}
                            initialaValue={text}
                            onChange={(value) => {
                            }}
                        />
                    </div> :
                    <div style={{ maxHeight: isExpanded ? "100%" : minHeightString, overflowY: "hidden", width: "100%", position: "relative", paddingLeft: editable ? "25px" : "0px" }}>
                        <div ref={elementRef} style={{ marginBottom: "0px", textAlign: "left", lineHeight: "22px", width: editable && !editing ? "95%" : "100%" }} >
                            <StyledText copyFromText={copyable} styledText={purifyStyledText(text)} />
                        </div>
                        <div className="edit-button" style={{}}>
                            {editable &&
                                <Tooltip title="Editar observación">
                                    <Button className={`expand-observation-button`} style={{ border: "none", width: "95%", color: "#35b6bc" }} size='small' onClick={() => { setEditing(true) }} >
                                        <EditOutlined />
                                    </Button>
                                </Tooltip>}
                        </div>
                    </div>}
            </div >
            {isExpandable && !editing &&
                <div style={{ display: "flex", gap: "5px", justifyContent: "center" }}>
                    <Button className={`expand-observation-button`} style={{ width: "100%" }} size='small' onClick={() => { expand(!isExpanded) }} >
                        {isExpanded ? "contraer" : "expandir"} {isExpanded ? <UpOutlined /> : <DownOutlined />}
                    </Button>
                </div>

            }
        </div >
    )
}

export default ExpandCollpaseStyledText;

