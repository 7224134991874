import { Button, Popover, Progress } from "antd";
import React, { useState } from "react";
import './index.less';
import ExpedientMovementService from "../../services/expedient_movement.service";
import StyledTextArea from "../StyledTextArea";
import { StyledText } from "../StyledTextArea/components/StyledText";
import { purifyStyledText } from "../../util/purifyText";


const ObservationAutocomplete = ({ initialValue, onChange }) => {
  const [controlledValue, setControlledValue] = useState(initialValue ? initialValue : '')
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [observer, setObserver] = useState();

  const debouncedSearch = (word) => {
    clearTimeout(observer);
    if (word !== "" && word !== null && word !== undefined) {
      setObserver(
        setTimeout(
          () => fetchObservations(word)
            .then((c) => {
              if (c) {
                setData(c)
              }
            })
          , 1500))
    } else {
      setData([])
    }
  }

  const fetchObservations = async word => {

    setLoading(true);
    const data = await ExpedientMovementService.getObservationsByStudio({ search: word, limit: 5 });
    let observations = data.data;
    setLoading(false);
    return observations;

  };

  return (
    <>
      <div style={{ position: "relative", width: "100%" }}>
        <StyledTextArea
          initialaValue={controlledValue}
          controlledValue={controlledValue}//Update this value, to force text area to update
          onChange={(styled_text, plain_text) => {
            debouncedSearch(plain_text);
            onChange(styled_text, plain_text);
          }}
        />
      </div>

      {loading && <Progress percent={99.9} showInfo={false} type="line" status="active" />}
      {data.length > 0 && !loading && data.map(m => (
        <Popover content={purifyStyledText(m, {
          ALLOWED_TAGS: [], CUSTOM_ELEMENT_HANDLING: {
            tagNameCheck: null,
            attributeNameCheck: null,
            allowCustomizedBuiltInElements: false,
          }
        }).substr(0, 300)}>
          <Button
            onClick={e => {
              setControlledValue(m);
              onChange && onChange(m);
              setData([]);
            }}
            block={true}
            className="completion-item" style={{ overflow: 'hidden', padding: "3px" }} >
            <StyledText copyFromText={false} styledText={m} />
          </Button>
        </Popover>
      ))}
    </>
  );
};

export default ObservationAutocomplete;
