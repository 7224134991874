import React, { useEffect, useRef, useState } from "react";
import { EditorState, ContentState, Modifier, RichUtils } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './styles.css';
import { defaultProps } from "./toolbarProps";
import { convertToRaw } from 'draft-js';

const StyledTextArea = ({
    initialaValue = '',
    onChange,
    onEdit = null,
    autoFocus = false,
    controlledValue, //Update this prop from outside to force update the editor value
    customStyle,
    placeholder = '',
    extraOptions = [] // React-Draft-Wysiwyg permite cualquiera de estas ["inline","blockType","fontSize","fontFamily","list","textAlign","colorPicker","link","embedded","emoji","image","remove","history" ]. Puede ser necesario mas cambios al codigo para implementar correctmente
}) => {

    const contentBlock = htmlToDraft(initialaValue);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState))
    const [isFocused, setIsFocused] = useState(false);

    const inputRef = useRef(null);

    const handleCopyData = (data) => {
        let currentState = editorState;
        let selectionState = editorState.getSelection();
        let anchorKey = selectionState.getAnchorKey();
        let currentContent = editorState.getCurrentContent();
        let currentContentBlock = currentContent.getBlockForKey(anchorKey);
        let start = selectionState.getStartOffset();
        let end = selectionState.getEndOffset();
        let selectedText = currentContentBlock.getText().slice(start, end);
        let inlineStyle = editorState.getCurrentInlineStyle();

        let newContent = Modifier.replaceText(currentContent, selectionState, selectedText + data, inlineStyle);

        let new_State = EditorState.push(currentState, newContent, 'add-data');
        let selection_after_push = new_State.getSelection();

        let state_after_push = EditorState.forceSelection(new_State, selection_after_push);

        let plain_text = state_after_push.getCurrentContent().getPlainText();
        let styled_text = draftToHtml(convertToRaw(state_after_push.getCurrentContent()));
        onChange(styled_text, plain_text)
        setEditorState(state_after_push);
    }

    useEffect(() => {
        if (autoFocus) {
            setEditorState(EditorState.moveFocusToEnd(editorState))
        }
    }, [])

    useEffect(() => {
        if (controlledValue) {
            const contentBlock = htmlToDraft(controlledValue);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const newState = EditorState.createWithContent(contentState);
            setEditorState(newState);
            let plain_text = newState.getCurrentContent().getPlainText();
            let styled_text = draftToHtml(convertToRaw(newState.getCurrentContent()));
            onChange(styled_text, plain_text);

        }
    }, [controlledValue])


    return (
        <div className="editor-border" style={{ position: "relative", paddingBottom: "25px" }} >

            <Editor
                handleReturn={(event) => {
                    let newEditorState = null;
                    if (event.keyCode === 13 && event.shiftKey) {
                        const currentContent = editorState.getCurrentContent();
                        const selection = editorState.getSelection();
                        const textWithEntity = Modifier.splitBlock(currentContent, selection);
                        newEditorState = EditorState.push(editorState, textWithEntity, "split-block");
                    } else if (event.keyCode === 13 && !event.shiftKey) {
                        newEditorState = RichUtils.insertSoftNewline(editorState);
                    }
                    if (newEditorState) {
                        setEditorState(newEditorState)
                        return true;
                    }
                    return false;
                }}

                ref={ref => {
                    inputRef.current = ref;
                }}
                toolbarStyle={{
                    border: "none",
                    justifyContent: "end",
                    position: "absolute",
                    backgroundColor: "transparent",
                    padding: "0px",
                    margin: "0px",
                    right: "10px",
                    bottom: "0px"

                }}
                handlePastedText={(text) => handleCopyData(text)}
                onFocus={(e) => setIsFocused(true)}
                onBlur={(e) => {
                    let plain_text = editorState.getCurrentContent().getPlainText();
                    let styled_text = draftToHtml(convertToRaw(editorState.getCurrentContent()));
                    if (onEdit) {
                        onEdit(styled_text, plain_text)
                    }
                    setIsFocused(false)
                }}
                wrapperStyle={{ padding: "0px" }}
                editorStyle={{ ...customStyle }}
                editorState={editorState}
                wrapperId="rtf-editor"
                wrapperClassName="demo-wrapper"
                editorClassName="draft-input"
                toolbarClassName="toolbar-class"
                toolbar={{ ...defaultProps, options: [...defaultProps.options, ...extraOptions] }}
                placeholder={placeholder}

                onEditorStateChange={(newState) => {

                    let previousContent = editorState.getCurrentContent()
                    let currentContent = newState.getCurrentContent()
                    let raw_content = convertToRaw(currentContent);
                    let plain_text = newState.getCurrentContent().getPlainText();
                    let styled_text = draftToHtml(raw_content);

                    const contentHasChanged = (previousContent && currentContent) && previousContent !== currentContent;
                    if (contentHasChanged) {
                        onChange(styled_text, plain_text)
                    }

                    setEditorState(newState)
                }}
                localization={{
                    locale: 'es',
                }}
            />
        </div>
    )
}

export default StyledTextArea;