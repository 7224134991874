import { WhatsAppOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { buildFormNumber } from '../../util/buildFormNumber';
import { isMobile } from "react-device-detect";

const SendRequestLinkWhatsapp = ({ expedient }) => {

  const {client} = expedient;
  const phoneIsCorrect = client.mobile_phone && client.client_country;

  return (
    <Button
      disabled={!phoneIsCorrect}
      size="small"
      className="option-button"
      icon={<WhatsAppOutlined />}
      onClick={() => {
        const link = encodeURIComponent(
          `${window.location.origin}/scan#${expedient.uuid}`
        );
        window.open(
          isMobile
            ? `https://wa.me/${buildFormNumber(expedient.client)}?text=${link}`
            : `https://web.whatsapp.com/send?text=${link}&phone=${buildFormNumber(
                expedient.client
              )}`,
          "_blank"
        );
      }}
    >
      Enviar whatsapp
    </Button>
  );
};

export default SendRequestLinkWhatsapp;
