import axios, { baseURL } from '../util/Api';

const StudioService = {
  createStudio: (studio, url = '/studio') => {
    return axios.post(url, studio, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createSubscription: async (id, data) => {
    const sub = await axios.post(`/studio/${id}/subscription`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return sub.data.subscription;
  },
  updateSubscription: async (id, data) => {
    const sub = await axios.patch(`/studio/${id}/subscription`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deactivateSubscription: async (studioId, subscriptionId) => {
    const sub = await axios.post(`/studio/${studioId}/subscription/${subscriptionId}/deactivate`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  resetFeatureUsage: async (studioId) => {
    const { data } = await axios.get(`/studio/${studioId}/reset-feature-usage`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
    return data;
  },
  getSubscription: async (id) => {
    const sub = await axios.get(`/studio/${id}/subscription`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return sub.data.subscription;
  },
  getStudios: (url = '/studio') => {
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getClients: () => {
    return axios.get('/studio/clients', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  }, getMyStudio: () => {
    return axios.get('/my-studio', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getClientsPg: (params = {}) => {
    return axios.get(`/paginate/client`, {
      params: params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  exportClientsAs: async (params = {}, type) => {
    return axios.get(`/export-clients-as`, {
      params: { ...params, type: type },
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(
        resp =>
          new Blob([resp.data], {
            type:
              type === "PDF"
                ? "application/pdf"
                : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
          })
      );;
  },
  createTrialStudio: (data) => {
    return axios.post(`/trial/create-trial-studio`, data);
  },
  isTrialActive: () => {
    return axios.get('is-trial-active', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  checkStudioIncrementFolder: (studio_id) => {
    return axios.get(`studio/get-auto-increment/folder/${studio_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  }, getNextFolder: (studio_id) => {
    return axios.get(`studio/get-auto-increment/folder/last/${studio_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getStudioAccountMovementsPg: (currentPage = 1, params) => {
    return axios.get(`/paginate/account-movement?page=${currentPage}`, {
      params: params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
  },
  getAccountMovementType: () => {
    return axios.get(`account-movement-type`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
  },
  createAccountMovement: (data) => {
    return axios.post(`account-movement`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
  },
  setAutoIncrementFolder: (data) => {
    return axios.post(`studio/set-auto-increment/folder`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
  }, updateAutoIncrementFolder: (data) => {
    return axios.post(`studio/update-auto-increment/folder`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
  }, disableAutoIncrementFolder: ($auto_increment_id) => {
    return axios.delete(`studio/disable-auto-increment/folder/${$auto_increment_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
  },
  deleteAccountMovement: (id) => {
    return axios.delete(`/account-movement/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
  },
  getStudioBalance: (id) => {
    return axios.get(`studio-balance/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
  },
  getBalanceList: () => {
    return axios.get(`studio-balance-list`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
  },
  updateAccountMovement: (data) => {
    return axios.patch(`/account-movement/${data.id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
  },
  checkAvailableStorage: (studio_id, size) => {
    return axios.get(`studio/check-available-storage/${studio_id}`,
      {
        params: {
          file_size: size
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
  },
  downloadBackup: (id, include_files) => {
    return fetch(`${baseURL}/studio/backup-info/${id}?include_files=${include_files}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateMevData: (data) => {
    return axios.post(`studio/mev-data`, {
      mev_user: data.username,
      mev_password: data.password
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
  },
  deleteMevData: () => {
    return axios.delete(`studio/mev-data`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
  },
  getMevData: () => {
    return axios.get(`studio/mev-data`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
  },
  verifyStudio: (id, data) => {
    return axios.post(`studio/${id}/verify`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
  }
}

export default StudioService;
